import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About page" />
    <h1>About Me</h1>
    <p>I'm Andro. I'm a software engineer that wants to learn how to do web development, so I'll be using this website as a learning tool and a place for me to experiment.</p>
    <p>On the way, I'll be blogging about my experiences building this site, as well as other random posts like other projects or opinions on some of my other hobbies.</p>
    <br/><br/>
    <p>If you'd like to get in contact with me, you can try reaching me at:</p>
    <ul>
      <li>Email: andropinpin90@gmail.com</li>
      <li><a href="https://www.linkedin.com/in/alejandro-pinpin">Linked In</a></li>
    </ul>
  </Layout>
)

export default AboutPage
